// Migrated
<template>
  <div
    ref="navBar"
    class="w-12/12 px-0 calendar-view-mode mb-1 pb-4 mt-4 flex !justify-between items-center flex-col xl:flex-row"
  >
    <div class="my-2 calendar-view-mode-type">
      <FilterBarType
        :airports="airports"
        @filter="$emit('filter')"
        @update="onUpdate"
      />
    </div>
    <div class="flex justify-end items-center flex-col sm:flex-row my-2">
      <button
        v-for="{ id, key } in options"
        :key="id"
        class="btn-link p-2 ml-4 text-black category-mode-toggle-btn"
        :class="{ 'text-blue font-bold': currentId == id }"
        @click="$emit('click', id)"
      >
        {{ $t(key) }}
      </button>
    </div>
  </div>
</template>

<script>
export default defineNuxtComponent({
  props: {
    currentId: {
      type: String,
      default: '',
    },

    options: {
      type: Array,
      default: () => [],
    },

    category: {
      type: Object,
      default: () => {},
    },
  },

  emits: ['update', 'filter', 'click'],

  computed: {
    airports () {
      return [
        {
          iata: 'all',
          name: this.$t('allAirports'),
        },
        // yes, it's misspelled.
        ...this.category?.availible_airports || [],
      ]
    },
  },

  methods: {
    onUpdate (value) {
      this.$emit('update', value)
    },
  },
})
</script>

<style lang="scss" scoped>
/*! purgecss start ignore */
.calendar-view-mode {
  .calendar-view-mode-type {
    @media (max-width: theme('screens.sm')) {
      width: 100%;
    }
  }
  .category-mode-toggle-airport {
    border: 1px solid theme('colors.light-gray');
  }
  button.category-mode-toggle-btn {
    letter-spacing: 0.34px;
    position: relative;

    &:focus, &:active {
      text-decoration: none;
    }
    @media (min-width: theme('screens.sm')) {
      & + button {
        &:before {
          content: "\a0";
          display: block;
          height: calc(100% - 1.25rem);
          top: .625rem;
          bottom: .625rem;
          width: 1px;
          position: absolute;
          left: -.5rem;
          background: gray;
        }
      }
    }
  }
}
/*! purgecss end ignore */
</style>
